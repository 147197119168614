.control {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.popover {
  border: none;
  border-radius: 4px;
  background: var(--white);
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: inherit !important;
  width: auto !important;
  margin-left: 8px;
  z-index: 1100;
  position: fixed;
  font-size: 12px;
  :global(&-body) {
    color: #fff;
  }
  &:global(.bs-popover-top),
  &:global(.bs-popover-top-start),
  &:global(.bs-popover-auto[x-placement^="top"]) {
    margin-bottom: 0.5rem;
    :global(.arrow) {
      z-index: 1100;
      bottom: -0.5rem;
      &:after {
        border-top-color: var(--white);
        border-width: 0.5rem 0.5rem 0;
      }
    }
  }
  &:global(.bs-popover-bottom),
  &:global(.bs-popover-bottom-start),
  &:global(.bs-popover-auto[x-placement^="bottom"]) {
    margin-top: 0.5rem;
    :global(.arrow) {
      top: -0.5rem;
      &:before,
      &:after {
        top: 1px;
        border-bottom-color: var(--white);
        border-width: 0 0.5rem 0.5rem 0.5rem;
      }
    }
  }
  &:global(.bs-popover-top-start),
  &:global(.bs-popover-bottom-start) {
    left: -3px !important;
    :global(.arrow) {
      left: 2px !important;
    }
  }
}

.dark {
  background-color: var(--black23);
  color: var(--white);
  &:global(.bs-popover-bottom),
  &:global(.bs-popover-bottom-start),
  &:global(.bs-popover-auto[x-placement^="bottom"])
  {
    :global(.arrow) {
      &::before, &::after {
        border-bottom-color: var(--black23) !important;
      }
    }
  }

  &:global(.bs-popover-top),
  &:global(.bs-popover-top-start) {
    :global(.arrow), :global(.popover-arrow) {
      &::before, &::after {
        border-top-color: var(--black23) !important;
      }
    }
  }
}

.light {
  background-color: var(--white);
  color: var(--black);
  &:global(.bs-popover-bottom), &:global(.bs-popover-bottom-start) {
    :global(.arrow) {
      &::before, &::after {
        border-bottom-color: var(--white) !important;
      }
    }
  }

  &:global(.bs-popover-top), &:global(.bs-popover-top-start) {
    :global(.arrow), :global(.popover-arrow) {
      &::before, &::after {
        border-top-color: var(--white) !important;
      }
    }
  }
}

.inner {
  cursor: help;
  fill: #CFD1D5;
}

.icon {
  display: flex;
  align-items: center;
}

.noMargin {
  margin: 0;
}
