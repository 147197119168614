.icon {
  color: var(--white);
}

.iconWrap {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.iconSuccess {
  background-color: var(--green);
}

.iconError {
  background-color: var(--red);
}

.toastCommon {
  border: 1px solid var(--black18);
  backdrop-filter: blur(20px);
  border-radius: 6px;
  overflow: inherit;
}

.progress {
  background-color: var(--blue);
  height: 4px;
  border-bottom-left-radius: 6px;
}

.dark {
  &.toastCommon {
    background-color: var(--black16);
    color: var(--white);
  }
}

.light {
  &.toastCommon {
    background-color: var(--grey7);
    color: var(--black);
  }
}

.btnClose {
  position: absolute;
  top: -11px;
  right: -11px;
  border: 1px solid var(--black18);
}