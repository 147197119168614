.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  height: 42px;
  overflow: hidden;
  border-radius: 4px;
  border-color: transparent;
}

.metaicon {
  margin-right: 6px;
}

.dropdownMenu {
  width: 157px;
  font-size: 14px;
  border-radius: 4px;
}

.menuItem {
  cursor: pointer;
}

.menuitemtext {
  padding: 10px 0;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.dark {
  .dropdown {
    background-color: var(--black9);
    color: var(--white);
    &:hover {
      background-color: var(--black3);
    }
  }
  .dropdownMenu {
    background: var(--black16);
    backdrop-filter: blur(52.5px);  
  }
  .menuItem {
    color: var(--white);
    &:hover {
      background: linear-gradient(90deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0) 97.65%);
    }
  }
  .menuitemtext {
    color: var(--white);
    &:hover, &:active {
      color: var(--white);
    }
  }
}

.light {
  .dropdown {
    background-color: var(--white);
    color: var(--black);
    &:hover {
      background-color: var(--grey9);
    }
  }
  .dropdownMenu {
    background-color: var(--white);
  }
  .menuItem {
    color: var(--black);
    &:hover {
      background: linear-gradient(90deg, rgba(0, 194, 255, 0.1) 0%, rgba(0, 194, 255, 0.1) 97.65%);
    }
  }
  .menuitemtext {
    color: var(--black);
    &:hover, &:active {
      color: var(--black);
    }
  }
}