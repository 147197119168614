.trigger {
  visibility: hidden;
}

.spinnerWrap {
  width: 100%;
  padding: 30px;
}

.spinnerText {
  margin-left: 12px;
}