.btn {
  background-color: var(--black9);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  font-size: 14px;
  width: 100%;
  color: inherit;
  &:after {
    content: none;
  }
  &:hover {
    background-color: var(--black3);
  }
}

.icon {
  margin-left: 6px;
  &:hover {
    color: var(--blue3);
  }
}
