.container {
  cursor: pointer;
  width: 251px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &.light {
    background-image: url('../../../../assets/icons/logo_sp_light.png');
  }
  &.dark {
    background-image: url('../../../../assets/icons/logo_sp_dark.png');
  }
}

@media screen and (max-width: 468px) {
  .container {
    width: 200px;
  }
}

@media screen and (max-width: 380px) {
  .container {
    width: 170px;
  }
}