.content {
  border-radius: 6px;
  padding: 20px 50px 20px 17px;
  &.light {
    border: 1px solid var(--grey2);
  }
  &.dark {
    border: 1px solid var(--black21);
  }
}

.img {
  margin-right: 6px;
}

.btn {
  margin-top: 16px;
  cursor: pointer;
}

.icon {
  margin-left: 14px;
  &.light {
    color: var(--black);
  }
  &.dark {
    color: var(--blue3);
  }
}