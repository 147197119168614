.dropdownToggle {
  border-radius: 6px;
  width: 178px;
  &.light {
    background-color: var(--black19);
    border: 1px solid var(--grey2);
  }
  &.dark {
    background-color: var(--black27);
    border: 1px solid var(--black7);
  }
}

.dropdownMenu {
  width: 178px;
  &.light {
    background-color: var(--grey25);
  }
}
