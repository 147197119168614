.content {
  display: inline-flex;
  align-items: center;
}

.iconWarn {
  color: var(--orange1);
  margin-left: 6px;
}

.warn {
  .text {
    color: var(--orange1);
  }
}

.ellipsisWrapper {
  width: 100%;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}