.menuItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
  padding: 13px 20px;
  &:active {
    background: transparent;
  }
}

.dark {
  &.menuItem {
    color: var(--white);
    &:hover {
      background: linear-gradient(90deg, rgba(249, 249, 249, 0.1) 0%, rgba(249, 249, 249, 0) 97.65%);
    }
  }
}

.light {
  &.menuItem {
    color: var(--black);
    &:hover {
      background: linear-gradient(90deg, rgba(0, 194, 255, 0.1) 0%, rgba(0, 194, 255, 0.1) 97.65%);
    }
  }
}

.icon {
  color: var(--white);
}

.opacity {
  opacity: 0.3;
}