$height: 65px;

.wrap {
  height: $height;
  padding: 0 40px;
  position: sticky;
  top: 0;
  z-index: 100;
  &.light {
    background: var(--grey14);
  }
  &.dark {
    background: linear-gradient(to right, var(--blue12), var(--blue13));
  }
}

.matic {
  margin-right: 13px;
}

.tee {
  margin-right: 20px;
}

.light {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02)
}

.content {
  flex: 1;
}

.contentMobile {
  display: none;
}

.mr {
  margin-right: 20px;
}

@media screen and (max-width: 1270px) {
  .wrap {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1270px) {
  .content {
    display: none;
  }
  .contentMobile {
    display: flex;
    flex: 1;
  }  
}