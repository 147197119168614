.showing {
  opacity: 0.3;
  margin-right: 6px;
}

.light {
  color: var(--black);
}

.dark {
  color: var(--white);
}