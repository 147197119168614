.title {
  margin-bottom: 15px;
  font-size: 20px;
}

.error {
  margin-bottom: 40px;
}

.dark {
  color: var(--white);
}

.subTitle {
  font-size: 12px;
  margin-bottom: 30px;
}

.textLink {
  font-weight: 300;
  border-bottom: 1px solid rgba(205, 91, 4, 1);
}

.link {
  color: inherit;
  text-decoration: none;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &:hover, &:active, &:visited {
    color: inherit;
  }
}

.info {
  margin-bottom: 16px;
  margin-top: 40px;
  font-size: 12px;
  padding: 10px;
  min-width: 333px;
}

.notificationIcon {
  margin-right: 8px;
  margin-top: 3px;
}

.icon {
  margin-left: 6px;
  margin-top: 3px;
}

.divider {
  margin-bottom: 16px;
}

.linkNotification {
  color: inherit;
  text-decoration: underline;
}