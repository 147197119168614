.content {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 13px;
  padding-top: 3px;
  &.light {
    border: 1px solid var(--white3);
    background-color: var(--black19);
  }
  &.dark {
    background-color: var(--black5);
  }
}

.content:hover {
  &.dark {
    background-color: var(--black28);
  }
  &.light {
    background-color: var(--white2);
  }
}

.link {
  color: inherit;
  text-decoration: none;
  &:hover, &:active {
    color: inherit;
  }
}
