$z-index: 1000; // for hide previous modal

.dialog {
  z-index: $z-index;
}

.backdrop {
  background: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
  &_dark {
    backdrop-filter: blur(7px);
    -moz-backdrop-filter: blur(7px);
  }
  &_light {
    backdrop-filter: blur(3.5px);
    -moz-backdrop-filter: blur(3.5px);
  }
  z-index: $z-index;
}

.content {
  &_dark {
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(105px);
    -moz-backdrop-filter: blur(105px);
  }
  &_light {
    background: var(--grey7);
  }
  border-radius: 6px;
  z-index: $z-index;
}

.btnClose {
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
}

.btnClose:hover {
  background-color: var(--white) !important;
}

.shadow {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.20);
}
