.container {
  margin-top: 40px;
}

.btnGroup {
  margin-top: 20px;
}

.btn {
  width: 186px;
}

@media screen and (max-width: 468px) {
  .container {
    margin-top: 50px;
  }
}

@media screen and (max-width: 428px) {
  .btn {
    width: 162px;
  }
}

@media screen and (max-width: 368px) {
  .btn {
    width: 142px;
  }
}