.contentMobile {
  display: none;
  position: sticky;
  top: 0;
  z-index: 100;
  &.light {
    background: var(--grey14);
  }
  &.dark {
    background: linear-gradient(to right, var(--blue12), var(--blue13));
  }
}

.mr {
  margin-right: 20px;
}

@media screen and (max-width: 1270px) {
  .contentMobile {
    display: flex;
    flex: 1;
  }  
}