.menu {
  width: 256px;
  max-height: 300px;
  overflow-y: scroll;
  &::-webkit-scrollbar-track {
    background: inherit;
  }
}

.toggle {
  padding: 0;
  height: unset;
}