.line {
  width: 100%;
  height: 1px;
  background-color: var(--grey1);
}

.dark {
  background-color: var(--grey1);
}

.light {
  background-color: var(--grey17);
}