.container {
  width: 491px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: blur(52.5px);
  z-index: 100;
  animation: show 0.3s linear;
  padding: 40px 33px 40px 30px;
  justify-content: space-between;
  &.light {
    background: var(--grey19);
  }
  &.dark {
    background: var(--black16);
  }
}

.hide {
  animation: hide 0.3s linear;
}

.left {
  width: 379px;
}

.btn {
  height: 42px;
  width: 100%;
}

@keyframes show {
  from { opacity: 0; }
}

@keyframes hide {
  to { opacity: 0; }
}

@media screen and (max-width: 568px) {
  .container {
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    padding: 27px 23px 20px;
  }
  .cross {
    display: none !important;
  }
  .left {
    width: 100%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 380px) {
  .container {
    padding: 20px;
  }
}