.container {
  display: none; 
}

@media screen and (max-width: 568px) {
  .container {
    display: flex;
  }
}

@media screen and (max-width: 468px) {
  .logo {
    width: 251px;
  }
}

@media screen and (max-width: 380px) {
  .logo {
    width: 251px;
  }
}