.dropdownToggle {
  padding: 0;
}

.content {
  margin-left: 8px;
}

.dropdownMenu {
  width: 182px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dialog {
  min-width: 460px;
}

.bodyModal {
  padding: 22px;
}