.balance {
  position: relative;
  font-size: 16px;
}

.dark {
  .text {
    color: var(--white);
  }
}

.light {
  .text {
    color: var(--black);
  }
}